import Menu from 'mmenu-js';

export const intializeMmenu = () => {
    const template = document.querySelector('#mmenu-template').innerHTML;

    return new Menu(
        '#wlymmenu',
        {
            wrappers: ['bootstrap'],
            extensions: ['fx-listitems-fade', 'border-offset', 'pagedim-black', 'theme-black'],
            onClick: {
                close: true,
                setSelected: true,
            },
            slidingSubmenus: true,
            navbars: [
                {
                    position: 'bottom',
                    content: [template],
                },
            ],
            setSelected: {
                current: 'detect',
                parent: true,
            },
        },
        {
            setSelected: {
                //
            },
        }
    );
};

document.addEventListener('DOMContentLoaded', () => {
    window.menu = intializeMmenu();
});
