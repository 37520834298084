export const setNewsStopperReadStatus = (stopper: Element) => {
    const id = stopper.getAttribute('data-stopper-id');
    const readItems = JSON.parse(localStorage.getItem('stopper-read-ids') ?? '[]');

    if (readItems.includes(id)) {
        stopper.classList.remove('stopper-notification');
    } else {
        stopper.classList.add('stopper-notification');
    }
}

export const handleNewsStopperClick = (stopper: Element) => {
    stopper.querySelector('.global-news-stopper-badge')?.addEventListener('click', () => {
        const id = stopper.getAttribute('data-stopper-id');
        const readItems = JSON.parse(localStorage.getItem('stopper-read-ids') ?? '[]');

        stopper.classList.toggle('open');

        if (stopper.classList.contains('open')) {
            if (!readItems.includes(id)) {
                readItems.push(id);
                localStorage.setItem('stopper-read-ids', JSON.stringify(readItems));
            }

            setNewsStopperReadStatus(stopper);
        }
    })
}

export const NewsStopper = () => {
    const stopper = document.querySelector('.global-news-stopper');

    if (!stopper) {
        return;
    }

    setNewsStopperReadStatus(stopper);
    handleNewsStopperClick(stopper);
}