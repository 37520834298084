export const BeforeAfterComparison = () => {
    document.querySelectorAll('.before-after-comparison').forEach(el => {
        const imageBefore = el.querySelector('.image-before') as HTMLElement | null;
        const slider = el.querySelector('input[type="range"]') as HTMLInputElement | null;
        const sliderButton = el.querySelector('.before-after-slider-button') as HTMLElement | null;

        if (!imageBefore) {
            console.warn('Could not find the .image-before element.');
            return;
        }

        if (!slider || !sliderButton) {
            console.warn('Could not find the input slider.');
            return;
        }

        // Reset the slider value so that we always start at 50%
        slider.value = "50";

        const updateBeforeImageWidth = () => {
            const width = Number.parseFloat(slider.value);
            imageBefore.style.clipPath = `polygon(0% 0%, ${width}% 0%, ${width}% 100%, 0% 100%)`;
            sliderButton.style.left = `${width * 0.995 + 0.25}%`;
        }

        updateBeforeImageWidth();
        slider.addEventListener('input', (e) => updateBeforeImageWidth());
    });
}
