// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
    document.querySelectorAll('.swiper-container-wly').forEach((el) => {
        let init = false;
        let id = el.getAttribute('data-swiper-id');
        let idSelector = `[data-swiper-id="${id}"]`;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 700,
            autoplay: {
                delay: 7000
            },
            grabCursor: true,
            pagination: {
                el: `${idSelector} ~ .swiper-pagination`,
                clickable: true
            },
            navigation: {
                nextEl: `${idSelector} .swiper-button-next`,
                prevEl: `${idSelector} .swiper-button-prev`
            }
        });

        swiper.on('init', () => {
            baguetteBox.run(`${idSelector} .swiper-wrapper`);
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }

            swiper.updateAutoHeight();
        });
    });
};
