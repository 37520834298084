import Autosize from './autosize';
import { lazyloadImages } from './images';
import { setActiveNavItem } from './navigation';
import { runIsotope } from './isotope';
import Baguettebox from './baguette-box';
import Swiper from './swiper';
import Teich from './teich';
import Accordion from './accordion';
import { NewsStopper } from './news-stopper';
import { BeforeAfterComparison } from './before-after-comparison';

function updateMmenu() {
    const menu = window.menu;
    const node: HTMLElement | undefined = menu?.node.menu;
    const path = window.location.origin + window.location.pathname;
    const link = node?.querySelector('[href="' + path + '"]')?.parentElement;

    if (link) {
        menu.setSelected(link);
        menu.API.openPanel(link.parentElement?.parentElement);
    }
}

/**
 * Will be called each time a new page has been loaded.
 */
export const onLoad = (container?: HTMLElement) => {
    Autosize(container);
    runIsotope(container);
    Baguettebox();
    Swiper();
    setActiveNavItem();
    lazyloadImages();
    Accordion(container);
    Teich();
    NewsStopper();
    BeforeAfterComparison();
    updateMmenu();
};
